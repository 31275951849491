import React, { useEffect, useRef, forwardRef,useImperativeHandle } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import Uploader from './uploader';
import './uploader.css';

import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

// import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import {getColor, saveImgPreview, getOpacity} from './utils';

FilePond.registerPlugin(FilePondPluginFileValidateType);

let renderer, cssRenderer, camera, mainMaterial,light, keylight, modelGroup, scene, pond, globalOffset; 
const Viewer = forwardRef((props,ref) => {
  const {file,fileType,color,previewLoaded=()=>{},onLoaded=()=>{},scale,handleAddFile,onUploadComplete} = props;
  const printerVolume = [350,350,350];  //x,z,y
  const mountRef = useRef(null);

  const exportFile = (type='blob') => {
      let exporter = new STLExporter();
      let stlData = exporter.parse(modelGroup, { binary: true });
      let out = stlData.buffer;
      if(type === 'blob') {
         out = new Blob([stlData.buffer], { type: "application/octet-stream" });
      }
      return out;
  }

  const togglePrintbed = (show) => {
    const pb = scene.getObjectByName("printbed");
    if (pb) {
      pb.visible = show; // true for show, false for hide
    }
    const logo = scene.getObjectByName("logo");
    if (logo) {
      logo.visible = show; // true for show, false for hide
    }
  }

  const getPreview = async () => {
    togglePrintbed(false);
    renderer.render(scene, camera); // Ensure the scene is rendered
    const tmp = await saveImgPreview(renderer.domElement);
    togglePrintbed(true);
    renderer.render(scene, camera); // Ensure the scene is rendered
    return tmp

  }

  const rotateModel = (dir) => {
    if(!modelGroup) {
      return;
    }
    switch (dir) {
      case 'left':
        modelGroup.rotation.y -= Math.PI / 2;
        break;
      case 'right':
        modelGroup.rotation.y += Math.PI / 2;
        break;
      case 'top':
        modelGroup.rotation.x -= Math.PI / 2;
        break;
      case 'bottom':
        modelGroup.rotation.x += Math.PI / 2;
        break;
    }
    centerModel();
  }

  useImperativeHandle(ref, () => ({
    getFile: exportFile,
    getPreview,
    uploadFile,
    rotateModel
  }));

  useEffect(()=>{
    if(!scene) {
      return;
    }
    const upl = scene.getObjectByName("uploader");
    if(file) {
      upl.visible = false;
      loadModel();
    } else {
      upl.visible = true;
      if(modelGroup) {
        scene.remove(modelGroup);
        modelGroup.traverse((child) => {
          if (child.isMesh) {
            child.geometry.dispose();
            child.material.dispose();
          }
        });
      }
      pond.removeFiles({});
      animate();
    }
    
  },[file]);

  useEffect(() => {
    // Set up the scene
    globalOffset = -100;
    const w = mountRef.current.clientWidth;
    const h = mountRef.current.clientHeight-80;
    // const w = mountRef.container.clientWidth, container.clientHeight window.innerWidth *.75;
    // const h = window.innerHeight - 80;
    const pixelRatio = window.devicePixelRatio;
    scene = new THREE.Scene();
    scene.background = null;
    scene.position.y = globalOffset; 
    // Set up the camera
    camera = new THREE.PerspectiveCamera(75, w / h, 0.1, 1000);
    camera.position.set( 21.08461421821844, 1.195245764001302, 356.74267441660754);
    camera.rotation.set(-0.0033504298121607365, 0.05903413953631988, 0.0001976756142904856);
    // window.camera = camera;

    // Set up the renderer
    renderer = new THREE.WebGLRenderer({alpha:true});
    renderer.setSize(w, h);
    renderer.localClippingEnabled = true;
    renderer.domElement.style.pointerEvents = 'none'; // Allow clicks to pass through
   

    cssRenderer = new CSS3DRenderer();
    cssRenderer.setSize(w, h);
    cssRenderer.domElement.style.position = 'absolute';
    cssRenderer.domElement.style.top = '0';
    cssRenderer.domElement.style.left = '0';
    // cssRenderer.domElement.style.transform = `scale(${1 / pixelRatio})`;
    cssRenderer.domElement.style.transformOrigin = '0 0'; // Ensure it scales from the top-left 
    // cssRenderer.domElement.style.zIndex = '100';
    mountRef.current.appendChild(cssRenderer.domElement);

    mountRef.current.appendChild(renderer.domElement);

    loadPrintbed();
    
    const cssObject = new CSS3DObject(Uploader);
    cssObject.name = "uploader";
    // cssObject.visible = false;
    // Set the position of the HTML element
    cssObject.position.set(0, 125, 0); // Place 1 unit above the cube
    // cssObject.rotation.x = -Math.PI / 2;
    cssObject.scale.set(.5, .5, .5);
    const center = new THREE.Vector3();
    const boundingBox = new THREE.Box3().setFromObject(cssObject);
    boundingBox.getCenter(center);
    // Reposition the mesh so that its center aligns with (0, 0, 0)
    cssObject.position.sub(center);
    scene.add(cssObject);

    // Set up lighting (optional, but good for STL models)
    light = new THREE.DirectionalLight(0xFFFFFF, 1);
    light.position.copy(camera.position);
    scene.add(light);

    keylight = new THREE.DirectionalLight(0xffffff, 1);
    keylight.position.set(50, 50, 100);
    scene.add(keylight);


    const ambientLight = new THREE.AmbientLight(0xFFFFFF); 
    scene.add(ambientLight);
    ambientLight.position.set(0, 1, 0);

    // Set up OrbitControls
    const controls = new OrbitControls(camera, cssRenderer.domElement);
    controls.enableDamping = true; // Enable damping (inertia) for smoother controls
    controls.dampingFactor = 0.25; // Adjust damping factor
    // controls.target.set(0, -150, 0);
    controls.update(); // Apply the changes
    // Animation loop
    
    animate();

    mountUploader();
    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      const w = mountRef.current.clientWidth;
      const h = mountRef.current.clientHeight-80;
      renderer.setSize(w, h);
      cssRenderer.setSize(w, h);
    };
    window.addEventListener('resize', handleResize);

    // Clean up on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
      mountRef.current.removeChild(cssRenderer.domElement);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mountUploader = () => {
    pond = FilePond.create(document.getElementById('filepond'), {
      allowMultiple: false,
      maxFiles: 1,
      server: {
        url: "/api/file",
        process: {
          onload: (resp) => {
            let data = JSON.parse(resp);
            return data.id;
          }
        }
      },
      instantUpload: false,
      onaddfile: handleAddFile,
      onprocessfile: (error, file) => {
        if (error) {
          console.error('Error processing file:', error);
          return;
        }
        // console.log('File processed, serverId:', file.serverId);
        onUploadComplete(file.serverId);
      },
      acceptedFileTypes: ['application/3mf', 'application/stl', 'application/obj'],
      fileValidateTypeLabelExpectedTypesMap: {
        'application/stl': '.stl',
        'application/3mf': '.3mf',
        'application/obj': '.obj'
      },
      fileValidateTypeDetectType: (source, type) => {
        return new Promise((resolve, reject) => {
          const ext = source.name.split('.').pop().toLowerCase();
          resolve('application/' + ext);
        });
      },
      labelIdle: '<img src="/icons/upload.svg" alt="upload"/><br/>Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
      credits: ''
    });
    var isChrome = navigator.userAgent.indexOf("Chrome") != -1;
    if(isChrome) {
      document.querySelectorAll('#uploadContainer a').forEach(item=>{
        console.log(item.href)
        if(item.href.indexOf('queu3d')<0) {
          item.addEventListener('pointerdown', () => {
            window.open(item.href,"_blank");
          });
        }
      });
      document.querySelector('.filepond--drop-label').addEventListener('pointerdown',pond.browse);
    } 
    document.querySelector('#uploadLink').addEventListener('pointerdown',pond.browse);
  }

  const uploadFile =  () => {
    pond.processFile();
  }

  const loadModel = () => {

    // Load the STL file
    let loader = new STLLoader();
    if(fileType?.toLowerCase() === '3mf') {
      // console.log("3mf Loader")
      loader = new ThreeMFLoader();
    }
    if(fileType?.toLowerCase() === 'obj') {
      // console.log("obj Loader")
      loader = new OBJLoader();
    }
    const reader = new FileReader();


    reader.onload = function(e) {
        const contents = e.target.result;
        modelGroup = loader.parse(contents);
        // console.log(modelGroup)
        if(!modelGroup) {
          console.error('sorry we couldn\'t read this model.' )
        }
        var hex = parseInt(getColor(color).replace(/^#/, ''), 16);
        let opacity = getOpacity(color);
        // Create a material for the model
        mainMaterial = new THREE.MeshPhysicalMaterial({  
          color: hex, 
          transparent:true,
          side:THREE.DoubleSide,  
          emissive:0x000000, 
          reflectivity: 1,
          ior: 1.5, 
          iridescenceIOR: 1.3,
          specularColor: 0xFFFFFF, 
          reflectivity: 0.9, 
          metalness: 0.5, 
          opacity:opacity,
          sheenRoughness: 1,
          roughness:1 
        });

        if(!modelGroup.isGroup) {
          const mesh = new THREE.Mesh(modelGroup, mainMaterial);
          modelGroup = new THREE.Group();
          modelGroup.add(mesh);
        } else {
          modelGroup.traverse(child => {
              if (child.isMesh) {
                  child.material = mainMaterial;
                  child.geometry.computeVertexNormals();
              }
          });
        }

        modelGroup.name = "modelGroup";
        // // Create a mesh and add it to the scene
        // mesh = new THREE.Mesh(geometry, mainMaterial);
        scene.add(modelGroup);

        modelGroup.rotation.x = -Math.PI / 2;
         // Compute the bounding box of the geometry
        const boundingBox = new THREE.Box3().setFromObject(modelGroup);

        // Compute the center of the bounding box
        const center = new THREE.Vector3();
        boundingBox.getCenter(center);

        // Reposition the mesh so that its center aligns with (0, 0, 0)
        modelGroup.position.sub(center);

        const offset = boundingBox.min.y+100;
        modelGroup.position.y = -offset;

        light.target = modelGroup;
        keylight.target = modelGroup;
        calcSize(modelGroup)
        requestAnimationFrame(() => {
          togglePrintbed(false);
          renderer.render(scene, camera); // Ensure the scene is rendered
          cssRenderer.render(scene, camera);
          saveImgPreview(renderer.domElement).then(previewLoaded);
          togglePrintbed(true);
        });
    };

    if (fileType?.toLowerCase() === 'obj') {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  const centerModel = () => {
    modelGroup.position.set(0,0,0);
    const boundingBox = new THREE.Box3().setFromObject(modelGroup);

    // Compute the center of the bounding box
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);    
    // Reposition the mesh so that its center aligns with (0, 0, 0)
    modelGroup.position.sub(center);
    const offset = boundingBox.min.y - globalOffset;
    modelGroup.position.y = -offset;
  }

  const loadPrintbed = () => {
    let printbedUrl = `/models/beds/bed.stl`;
    let STLloader = new STLLoader();
    STLloader.load(printbedUrl,(geometry)=>{
        
        const clippingPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0),100.9);
        // clippingPlane.rotation.x = -Math.PI / 2; // Rotate if needed
        const material = new THREE.MeshPhongMaterial({ color: 0x000000,side:THREE.FrontSide, clippingPlanes:[clippingPlane]  }); // Black material
        const mesh = new THREE.Mesh(geometry, material);
        mesh.name = 'printbed';
        scene.add(mesh);
        
        const scaleWidth = printerVolume[0] / 235;
        const scaleLength = printerVolume[1] / 235;
        mesh.scale.set(scaleWidth,scaleLength,1);
        mesh.rotation.x = -Math.PI / 2; // Rotate if needed
        mesh.position.set(0, -1, 0);
        const jsonURL = "/models/logo/logo.json";
        fetch(jsonURL)
        .then(response => response.json())
        .then(json => {
            const objectLoader = new THREE.ObjectLoader();
            const loadedObject = objectLoader.parse(json);
            loadedObject.name='logo';
            const [x, y] = printerVolume;
            let posY = y/2 - 15;
            scene.add(loadedObject);
            loadedObject.rotation.x = -Math.PI / 2;
            loadedObject.rotation.y = -Math.PI;
            loadedObject.position.set(x/2 - 15, .1, posY); 
            loadedObject.scale.set(.15,.15,.15);
        }).catch(error => console.error('Error loading JSON object:', error));
    });
  }

  const updateLightPosition = () => {
    // Set the light's position to the camera's position
    light.position.copy(camera.position);
    
    // Ensure the light is always pointing towards the object
    // light.target.updateMatrixWorld();
  }

  const animate = () => {
    requestAnimationFrame(animate);
    updateLightPosition();
    renderer.render(scene, camera);
    cssRenderer.render(scene, camera);
    // Inside the animate function

  };

  const calcSize = (group) => {
    const boundingBox = new THREE.Box3().setFromObject(group);
    // const geometry = mesh.geometry;
    const modelVolume = computeVolume(group);  // Total solid volume of the model (in mm³)
    const modelSize = getDimmensionsFromBoundingBox(boundingBox);
    const surfaceAreaVolume = computeSurfaceArea(group);
    const oversized = !(modelSize.width <= printerVolume[0] && modelSize.depth <= printerVolume[1] && modelSize.height <= printerVolume[2]);
    onLoaded({modelVolume,modelSize, surfaceAreaVolume, oversized});
}

  useEffect(()=>{
    if(mainMaterial) {
        const hexColor = parseInt(getColor(color).replace(/^#/, ''), 16);
        let opacity = getOpacity(color);
        mainMaterial.color.set(hexColor);
        mainMaterial.opacity = opacity;
    }
  },[color]);

  useEffect(()=>{
    if(modelGroup) {
      let factor = scale/100;
      modelGroup.scale.set(factor, factor, factor);
      let boundingBox = new THREE.Box3().setFromObject(modelGroup);
      // Compute the center of the bounding box
      const center = new THREE.Vector3();
      boundingBox.getCenter(center);
      // Reposition the mesh so that its center aligns with (0, 0, 0)
      modelGroup.position.sub(center);
      modelGroup.position.y = 0;
      boundingBox = new THREE.Box3().setFromObject(modelGroup);
      const offset = boundingBox.min.y-globalOffset;
      modelGroup.position.y = -offset;
      calcSize(modelGroup);
    }
  },[scale])

  return  <div ref={mountRef} style={{position:'relative', width:'100%', height:'100%', marginTop:80}}/>
  ;
});

export default Viewer;

function computeSurfaceArea(geometry) {
  return 0;
  let surfaceArea = 0;
  const position = geometry.attributes.position.array;

  // Iterate through each triangle in the geometry
  for (let i = 0; i < position.length; i += 9) {
      const v0 = new THREE.Vector3(position[i], position[i + 1], position[i + 2]);
      const v1 = new THREE.Vector3(position[i + 3], position[i + 4], position[i + 5]);
      const v2 = new THREE.Vector3(position[i + 6], position[i + 7], position[i + 8]);

      // Compute the area of the triangle using the cross product
      const crossProduct = new THREE.Vector3().crossVectors(
          new THREE.Vector3().subVectors(v1, v0),
          new THREE.Vector3().subVectors(v2, v0)
      );

      // Area of the triangle (1/2 * magnitude of cross product)
      const triangleArea = crossProduct.length() / 2.0;

      // Accumulate the surface area
      surfaceArea += triangleArea;
  }
  const wallThickness = 1.2;  // Wall thickness in mm

  // Convert surface area from mm² to cm² (divide by 100) and wall thickness from mm to cm (divide by 10)
  const volume = (surfaceArea / 100) * (wallThickness / 10);  // Volume in cm³
  return volume; // Surface area in the same units as the model (e.g., mm²)
}




function computeVolume(group) {
    let volume = 0;
    group.traverse(child=>{
      if (child.isMesh) {
        const {geometry} = child;
        const position = geometry.attributes.position;
        const index = geometry.index ? geometry.index.array : null;
        
        const vectorA = new THREE.Vector3();
        const vectorB = new THREE.Vector3();
        const vectorC = new THREE.Vector3();

        // Loop over each triangle in the geometry
        for (let i = 0; i < position.count; i += 3) {
            if (index) {
                vectorA.fromBufferAttribute(position, index[i]);
                vectorB.fromBufferAttribute(position, index[i + 1]);
                vectorC.fromBufferAttribute(position, index[i + 2]);
            } else {
                vectorA.fromBufferAttribute(position, i);
                vectorB.fromBufferAttribute(position, i + 1);
                vectorC.fromBufferAttribute(position, i + 2);
            }

            // Calculate the signed volume of the tetrahedron formed by the triangle and the origin
            volume += vectorA.dot(vectorB.cross(vectorC)) / 6.0;
        }
      }
    });

    return Math.abs(volume);

  // let volume = 0;
  // const position = geometry.attributes.position.array;

  // // Iterate through each triangle in the geometry
  // for (let i = 0; i < position.length; i += 9) {
  //     const v0 = new THREE.Vector3(position[i], position[i + 1], position[i + 2]);
  //     const v1 = new THREE.Vector3(position[i + 3], position[i + 4], position[i + 5]);
  //     const v2 = new THREE.Vector3(position[i + 6], position[i + 7], position[i + 8]);

  //     // Compute cross product and dot product for the volume of the tetrahedron
  //     const crossProduct = new THREE.Vector3().crossVectors(v1, v2);
  //     const tetraVolume = v0.dot(crossProduct) / 6.0;

  //     // Accumulate the absolute value of the volume (since some volumes can be negative)
  //     volume += Math.abs(tetraVolume);
  // }

  // return volume; // Volume in the same units as the model (e.g., mm³)
}

function calculateVoxelVolume(mesh, voxelSize) {
  const geometry = mesh.geometry;

  // Compute the bounding box of the geometry
  geometry.computeBoundingBox();
  const bbox = geometry.boundingBox;

  // Get the dimensions of the bounding box
  const min = bbox.min;
  const max = bbox.max;

  // Calculate the number of voxels in each direction
  const sizeX = Math.ceil((max.x - min.x) / voxelSize);
  const sizeY = Math.ceil((max.y - min.y) / voxelSize);
  const sizeZ = Math.ceil((max.z - min.z) / voxelSize);

  let voxelCount = 0;

  // Loop through each voxel
  for (let i = 0; i < sizeX; i++) {
    for (let j = 0; j < sizeY; j++) {
      for (let k = 0; k < sizeZ; k++) {
        // Calculate the center of the current voxel
        const x = min.x + i * voxelSize + voxelSize / 2;
        const y = min.y + j * voxelSize + voxelSize / 2;
        const z = min.z + k * voxelSize + voxelSize / 2;
        const voxelCenter = new THREE.Vector3(x, y, z);

        // Check if this point is inside the mesh using raycasting
        if (isPointInsideMesh(voxelCenter, mesh)) {
          voxelCount++;
        }
      }
    }
  }

  // Return the total volume in mm³ (voxelCount * voxelVolume)
  const voxelVolume = Math.pow(voxelSize, 3);
  return voxelCount * voxelVolume;
}

/**
 * Helper function to check if a point is inside a mesh using raycasting.
 * @param {THREE.Vector3} point - The point to check.
 * @param {THREE.Mesh} mesh - The mesh to test against.
 * @returns {boolean} - True if the point is inside the mesh, false otherwise.
 */
function isPointInsideMesh(point, mesh) {
  const raycaster = new THREE.Raycaster();

  // Cast a ray in an arbitrary direction (e.g., along the positive X-axis)
  const direction = new THREE.Vector3(1, 0, 0);
  raycaster.set(point, direction);

  // Check how many intersections we get
  const intersections = raycaster.intersectObject(mesh, true);

  // If the number of intersections is odd, the point is inside the mesh
  return intersections.length % 2 === 1;
}

function getDimmensionsFromBoundingBox(boundingBox) {
  // Create a Box3 instance and compute the bounding box

  // Get the minimum and maximum points
  const min = boundingBox.min;
  const max = boundingBox.max;

  // Calculate dimensions
  const width = max.x - min.x;
  const height = max.y - min.y;
  const depth = max.z - min.z;

  return { width, height, depth };
}

